import React, { Component } from 'react'

// import bytes from 'bytes' // what was this for?
import PropTypes from 'prop-types'

import headerGetters from '../../lib/headerGetters'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib } from '@fortawesome/fontawesome-svg-core'
import { faCircle, faSquare } from '@fortawesome/free-solid-svg-icons'

import MediaRecorderPolyfill from 'audio-recorder-polyfill'

if ('undefined' === typeof MediaRecorder) {
  window.MediaRecorder = MediaRecorderPolyfill
}

fLib.add(faCircle, faSquare)

export default class AudioRecorder extends Component {
  static propTypes = {
    relayAudioFileInfo: PropTypes.func.isRequired,
    relayTemporaryAudioUrl: PropTypes.func.isRequired,
    postUrl: PropTypes.string.isRequired,
  }

  state = {}

  audioChunks = []

  handleData = e => {
    this.audioChunks.push(e.data)
  }

  startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.mediaStream = stream

      this.mediaRecorder = new MediaRecorder(stream)

      this.mediaRecorder.addEventListener('stop', this.saveRecordedAudio)
      this.mediaRecorder.addEventListener('dataavailable', this.handleData)

      this.mediaRecorder.start()
      this.setState({
        mediaRecorderState: this.mediaRecorder.state,
      })
    })
  }

  stopRecording = () => {
    this.mediaRecorder.stop()
    this.mediaStream.getTracks()[0].stop()
    this.setState({
      mediaRecorderState: this.mediaRecorder.state,
    })
  }

  saveRecordedAudio = () => {
    const blob = new Blob(this.audioChunks, {
      type: 'audio/ogg; codecs="opus"',
    })
    this.audioChunks = []
    // future: there's some cool visualization stuff you can do while recording. It would be nice to show a color or bar or histo or something.../// see: https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder
    this.saveAudioBlob(blob)
  }

  saveAudioBlob = blob => {
    const { relayAudioFileInfo, postUrl } = this.props
    const fd = new FormData()
    fd.append('track', blob /*, `block-${blockId}.audiorecording`*/)
    const opts = {
      // future: the component will/should not be doing this... the data-model-object would be doing this...
      headers: headerGetters.withTokenAndNoContentType(),
      method: 'POST',
      body: fd,
    }
    fetch(postUrl, opts)
      .then(res => res.json())
      .then(saveBlobRes => {
        const { file: audioFile } = saveBlobRes

        relayAudioFileInfo(audioFile)
      })
  }

  render() {
    const { mediaRecorderState, audioSizePretty, audioUrlRecorded } = this.state

    return (
      <span className="audioRecorder">
        {'recording' === mediaRecorderState ? (
          <button className="stopRecording" onClick={this.stopRecording}>
            <Icon icon="square" />
          </button>
        ) : (
          <button className="startRecording" onClick={this.startRecording}>
            <Icon icon="circle" />
          </button>
        )}{' '}
        {/* eslint-disable-next-line */}
        {audioUrlRecorded && <audio controls src={audioUrlRecorded} />}
        {audioSizePretty}
      </span>
    )
  }
}
