import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as faLib } from '@fortawesome/fontawesome-svg-core'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

import { UserConsumer, DebugUser } from '../../contexts/UserContext'

const { APP_DISPLAY_NAME } = process.env

faLib.add(faVolumeUp)

const t = t => t

// I really like this code-design; the component acts as a quick summary and (in smart text-editors) a TOC to the "subcomponent" functions below.
export default function Header() {
  return (
    <div id="header">
      <ToggleDebugBtn />
      <DebugUser />
      <Branding />
      <Nav />
      {/* NOTE: If I ever wanted to have Routines featured in Header Nav, we would need RoutinesContext. Which honstly probably does make sense. Routines will transcend the context of the main page in which they're viewed. May want to see Stats by routine in a separate Stats area, etc. */}
      <div style={{ clear: 'both' }} />
    </div>
  )
}

const Branding = () => (
  <div className="branding">
    <h1>
      <Link to="/">
        <Icon icon="volume-up" /> {t(APP_DISPLAY_NAME)}
      </Link>
    </h1>
  </div>
)

const Nav = () => (
  <div className="nav">
    <UserConsumer>
      {({ isAuthenticated, user: userData }) => {
        if (isAuthenticated && isAuthenticated()) {
          return <LoggedInNav userData={userData} />
        } else {
          return <LoggedOutNav />
        }
      }}
    </UserConsumer>
  </div>
)

const LoggedInNav = ({ userData }) => {
  if (!userData) return null
  const { username } = userData
  return (
    <ul>
      <li>
        <Link to="/routines">Routines</Link>
      </li>
      <li>
        <Link to="/account">Account ({username})</Link>
      </li>
    </ul>
  )
}

const LoggedOutNav = () => {
  return (
    null && (
      <ul>
        <li>You know, you should probably just log in</li>
      </ul>
    )
  )
}

const ToggleDebugBtn = () => {
  return (
    <UserConsumer>
      {user => (
        <button style={{ float: 'right' }} onClick={user.toggleDebugging}>
          {'{ }'}
        </button>
      )}
    </UserConsumer>
  )
}
