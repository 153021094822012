import React, { Component } from 'react'

import PropTypes from 'prop-types'

export default class AudioPlayerSimple extends Component {
  static propTypes = {
    audioUrl: PropTypes.string,
    audioVolume: PropTypes.number,
    description: PropTypes.string,
  }

  audioRef = React.createRef()

  play = () => {
    const { audioVolume } = this.props
    if (this.audioRef && this.audioRef.current && this.audioRef.current.play) {
      this.audioRef.current.volume = audioVolume
      const playbackPromise = new Promise((resolve, reject) => {
        this.audioEndedCb = () => {
          resolve()
        }
        this.audioErrorCb = reject
        try {
          this.audioRef.current.play()
        } catch (e) {
          console.error(e)
          reject()
        }
      })
      return playbackPromise
    } else {
      const instaPromise = new Promise(resolve => resolve())
      return instaPromise
    }
  }

  pause = () => {
    this.audioRef.current.pause()
  }

  handleAudioError = e => {
    e
    const { description, audioUrl } = this.props

    alert(
      `error: audio for block "${description}" with url ${audioUrl} failed, with error ${this.audioRef.current.error.code}; details: ${this.audioRef.current.error.message}; please send a screenshot of this message to your app administrator.`
    )

    if (this.audioErrorCb) {
      console.error('firing this.audioErrorCb()')
      this.audioErrorCb()
    } else {
      console.error('no audioErrorCb present')
    }
  }

  handleAudioEnded = /* e */ () => {
    const that = this // helps with chrome debugging
    that.audioEndedCb()
  }

  render() {
    const { audioUrl /* , audioFile */ } = this.props

    return audioUrl ? (
      <span className="audio">
        {/* eslint-disable-next-line */}
        <audio
          controls
          ref={this.audioRef}
          src={audioUrl}
          onEnded={this.handleAudioEnded}
          onError={this.handleAudioError}
        />
        {/* controlsList="nodownload" */}
      </span>
    ) : null
  }
}
