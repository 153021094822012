export default {
  'Before letting friends and family try it': [],
  'Before talking to startup-business consultant': [
    'use audioFileName instead of audioUrl because audioUrl changes on refactor',
    'looks some for for competitors. Maybe email this article writer: https://medium.com/@mahenratnayake/use-these-7-apps-to-create-awesome-routines-and-have-better-days-9485b3948b3b',
  ],
  'Before offering to go to appointments with family to record exercises': [],
  'Before showcasing / sharing / etc. / inviting Dave etc. to try it personally': [
    'make conditionals',
    'disable background video button (also way to set volume if possible)',
    'ADD FIELDS:',
    'durationBeforeStart',
    'innerDuration',
    'durationAfterEnd',
    'time strings. 3m5s, or 3:05, 2h3m5, 2:03:05',
    'no onMouseOvers',
    'DIVIDE INTO "contentType"s: guided reps, unguided duration, manual completion, sub-blocks, another routine',
    'BUT: it is a low priority. because it can be accomplished with subroutines. BETTER YET: "Play once or multiple times?": "Single Playback", "Multiple (Numbered) Playbacks", "Multiple Differently-Named Playbacks (e.g. "Left" + "Right"). offer 3 diffrent iterationType UIs for setting iterations. default: single. next: simple # times through, default to 1. alternate: custom iterations with titles and audios.     EARLIER THOUGHTS: Lateralities is same as timesThrough; "iterations".; "1st/First Set" (js number to (written?) ordinal) is no different than "Left"... or is it? Yes. Because if we allow both, then we introduce an ambiguity as to which iteration (sets or lateralities) is outer and which is inner. Anyway, I think we avoid the nomenclature of "set". Because then you have "session", "set", "laterality", and all these other possible models. When probably all you want is [somePlural] > reps. And by using "sub-blocks", nested functionalities can be created in any way. "First Session">"First Set", "First Module" > "Left", etc. We can even allow people a little [3]x[Set] thing to populate it. As well as "copy from", using order by iterationsLastCopied desc. (can also use settingsLastCopied for a broader application)',
    'collapse blocks, allow 1 sibling open',
    'fix justMoved thing',
    'refactor block into more controls',
    'figure out why ham stretch and pushups plus not doing multiple reps',
    'memoize block contents (probably not children though) - https://medium.com/corebuild-software/easily-optimise-your-react-native-components-rendering-8deb22176c8a ... and also ... nested child components: const Foo = memo(({ children }) => (<div>{children}</div>)); It wasnt obvious to me until recently, but the memo() in this example does nothing unless the children prop is memoized before passing it in, or if its a primitive value, because React.createElement() creates a new object each time. A straightforward solution would be to memoize the children before passing them in, but this puts the onus on the consumer of the component, and that might not be appropriate for something like a library, where you need to plan for users making mistakes.',

    'Block for manual-advance, including URL link',
    'If the routine has a path: Are you sure you want to leave? If you do, you will lose your place in this routine. - OR - save playback status to db/localstorage',
    'Text-to-speech',
    'Password Reset',
  ],
  'Before inviting Dave etc. to use it professionally (i.e. hiring myself out to do its data entry, doing for patients what I did for family)': [
    'Make it HIPAA-secure',
    'Get a HIPPA-security audit/certification for a given version of it',
  ],
  'Before talking to a not-extremely-trusted UX-dev': [
    'talk to the biz-consultant',
    'establish some IP per biz-consultant',
  ],
  'Before publicizing it anywhere': [
    'Spacebar to pause',
    'Allow timestrings HH:MM:SS in PauseDur field',
    'Show time totals for each block',
    'Way to go to end of block-child, to advance rep but not advance block.',
    'Crossfade volume and opacity for background-videos?',
    'Ability to "disable" a block without modifying its numReps etc. (also prevent numReps = 0, just set to disabled instead if they enter 0)',
    'Minify source code and omit map from production servers',
    'Play a routine from inside another routine',
    'Play a block from somewhere else in the current routine',
    'Revisit security for requests between servers ("network/internal peering"?)',
    'Upload audio files',
    'Share routines and play-history',
    'View play-history',
    'Better pause emoji', // non-orange pause emoji: https://stackoverflow.com/questions/32413731/color-for-unicode-emoji
  ],
  'Nice additional features': [
    'Securely send play-history to other users',
    'Download block audio files (if you are the owner)',
    'Export routine audio (if you are the owner)',
  ],
}

///////

export const nerd = [
  // https://kentcdodds.com/blog/how-to-use-react-context-effectively
  '.getAudioUrl() instead of .audioUrl',
  '500 blocks?? ~500 tracks?? 28 routines??',
  'use a single query to get all blocks in a routine, and store them in a single Context object. much less hassle of passing functions up and down the recursive hierarchy, AND smoother interactions. (for example, not needing to reload data about a block after moving it to a new parent',
  'stop storing originals. just wastes space and creates more cleanup work. just store a single AUDIO FILE per audio.',
  "Implement a better fix to reset/clear all data when user logs out (so that you don't see the previous person's routines when you've logged them out and you in...)",
  {
    name: 'dealing with split servers',
    todos: [
      "don't require logout and login after approval", //'MAYBE Make token auto-regen on each pre-approved page-load (or every page load?) to fix the log-out-and-in-to-update-userdata-issue. Or find some other way around that issue.',
      "Verify with identity-server that user-account hasn't been deleted since user logged in",
    ],
  },
  // 'Set ownership at the block level?'// no. stay really denormalized about it; propagate owner, writer, reader roles to all child blocks, for performance reasons.
  // 'Remove secret keys from servers where they are not needed',
  "Make it easier for multiple developers to safely see the app's secret keys", // Possibly store configuration files, encrypted, in repo: https://medium.freecodecamp.org/how-to-securely-store-api-keys-4ff3ea19ebda
  'replace asyncForEach() with Promise.all()', // replace var f = thing=>thing.doSomethingAsync() ;  asyncForEach(things, f) with Promise.all(things.map(f))
  'replace .depth with ancestors.length.',
]

export const bugFixes = [
  // "Deletion of last block in a block-container should remove the block-container"
  'fix server shutting itself down', // https://stackoverflow.com/questions/19882344/node-js-server-crashing-without-error-message
]

/////

export const nope = [
  /*
  'Consider using Blockly as interface engine https://www.youtube.com/watch?v=m526vz4v3Nw',
*/
]

export const completedPhases = {
  'before letting friends and fam try it': [
    // "Add a pause-duration after a block's audio",
    // "Move blocks within a block",
    // "User login",
    // "Pause/resume workout during an audio-play",
    // "Only logged-in users able to see app content",
    // "Don't auto-log them in when they create an account; just tell them they'll be notified when approval is granted.",
    // 'Live install on cloud servers',
    // 'Adjust audio volume',
    // 'Get it working in-browser cross-platform
  ],
}
