// alert`hi`;

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PrivateRoute from './common/PrivateRoute'

import Header from './common/Header.js'

import HomePage from './HomePage'
import AboutPage from './AboutPage'
import PlayerPage from './PlayerPage'
import NotFoundPage from './NotFoundPage'
import AccountPage from './AccountPage'
import LoginPage from './LoginPage'

import Routines from './Routines'

import { WithUser } from '../contexts/UserContext'

class Index extends Component {
  render() {
    return (
      //  when I was putting the navigate() in here, it was... it wasn't redirecting every time;

      <div id="index">
        <Router>
          <div>
            <Header />
            <div id="body">
              <Switch>
                <PrivateRoute exact path="/" component={HomePage} />

                <Route path="/account" component={AccountPage} />
                <Route path="/login" component={LoginPage} />
                <PrivateRoute path="/routines" component={Routines} />

                <PrivateRoute path="/about" component={AboutPage} />
                <PrivateRoute path="/player" component={PlayerPage} />

                <NotFoundPage />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    )
  }
}

export default WithUser(Index)
