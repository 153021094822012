import React from 'react'

import { Route } from 'react-router-dom'

import { WithUser } from '../../contexts/UserContext'

import { UnapprovedMessage } from './ApprovalRequired'
import { UnAuthMessage, AuthUndeterminedMessage } from './AuthRequired'


function PrivateRoute({ user, component: Component, ...rest }) {
    const { isAuthenticated, isUnauthenticated, isApproved } = user

    const render = (props) => {
        if (isAuthenticated && isAuthenticated()) {
            if (isApproved && isApproved()) {
                return <Component {...props} />
            } else {
                return <UnapprovedMessage />
            }
        } else {
            if (isUnauthenticated && isUnauthenticated()) {
                return <UnAuthMessage />
            } else {
                return <AuthUndeterminedMessage />
            }
        }
    }

    return (
        <Route
            render={render}
            {...rest}
        />
    )
}

export default WithUser(PrivateRoute)