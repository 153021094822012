import React, { Component } from 'react'
// import { navigate } from '@reach/routerDISABLED'

import { IDENTITY_API_URL, BACKEND_API_URL } from '../lib/serverUrls'

const UserContext = React.createContext({
  name: 'y u no put user?',
})

const loggedOutState = {
  user: {
    name: 'Guest',
  },
  message: '',
  tokenConfirmed: false,
  justLoggedIn: false,
  lastLoginFailed: null,
  lastRegFailed: null,
  lastRegSucceeded: null,
}
export class UserProvider extends Component {
  state = Object.assign({}, loggedOutState)

  isAuthenticated = () => {
    const { tokenConfirmed, justLoggedIn } = this.state
    return !!(tokenConfirmed || justLoggedIn)
  }
  isUnauthenticated = () => {
    const token = localStorage.getItem('token')
    return null == token || 'undefined' == token
  }
  isApproved = () => {
    const { user } = this.state
    return !!user.approved
  }

  toggleDebugging = () => {
    localStorage.setItem('debugging', !this.debuggingIsOn())
    this.forceUpdate()
  }

  debuggingIsOn = () => {
    const debugging = 'true' === localStorage.getItem('debugging')
    return debugging
  }

  register = ({ username, email, password }) => {
    // NOTE: this is working almost the same same as login... maybe DRY this up...
    const url = `${IDENTITY_API_URL}/register`,
      opts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          email,
          password,
        }),
      }

    fetch(url, opts).then(res => {
      if (res.ok) {
        res
          .json()

          /* We will re-enable this when we're not requiring manual approval anymore. In the meantime, it's pointless to log them in if they'll just need to log out when they get approval.
                        .then(({ user, message, token }) => {
                            localStorage.setItem('token', token)
                            this.setState({
                                user,
                                message,
                                token,
                                lastRegFailed: false,
                                justLoggedIn: true,
                            })
                        })
                        */

          .then(({ success }) => {
            if (success) {
              this.setState({
                lastRegSucceeded: true,
                lastRegFailed: false,
              })
            }
          })
      } else {
        console.warn({ res })
        this.setState({
          ...loggedOutState,
          lastRegFailed: true,
          lastRegSucceeded: false,
        })
      }
    })
  }

  logIn = ({ username, password }) => {
    const url = `${IDENTITY_API_URL}/login`,
      opts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      }

    fetch(url, opts).then(res => {
      if (res.ok) {
        res.json().then(({ user, message, token }) => {
          localStorage.setItem('token', token)

          location.reload() // Hackish, since the routines etc. components were keeping their data (or not coming in right away). Need better fix later.

          this.setState({
            user,
            message,
            token,
            lastLoginFailed: false,
            justLoggedIn: true,
          })
        })
      } else {
        console.warn({ res })
        this.setState({
          ...loggedOutState,
          lastLoginFailed: true,
        })
      }
    })
  }

  logOut = () => {
    localStorage.removeItem('token')

    location.reload() // Hackish, since the routines etc. components were keeping their data. Need better fix later.

    this.setState(loggedOutState)
    // navigate('/login')
  }

  confirmAuth = () => {
    const token = localStorage.getItem('token') // TODO: make it more consistent, when we use localStorage.getInfo('token') or UserContext.token...

    if ('undefined' == token || null == token) {
      this.setState(loggedOutState)
      // navigate('/login')
      return
    }

    const fetchUrl = `${BACKEND_API_URL}/confirm-token`
    const fetchOpts = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    fetch(fetchUrl, fetchOpts)
      .then(res => (res.ok ? res.json() : res))
      .then(res => {
        if ('token confirmed' === res.message) {
          this.setState({
            tokenConfirmed: true,
            justLoggedIn: false,
            user: res.user,
          })
        } else {
          console.info('token failed validation; res:', res)
          localStorage.removeItem('token')
          this.setState(loggedOutState)
        }
      })
  }

  componentDidMount() {
    this.confirmAuth()
  }

  render() {
    const { children } = this.props
    const { user, message, lastLoginFailed, lastRegFailed, lastRegSucceeded } = this.state
    return (
      <UserContext.Provider
        value={{
          logOut: this.logOut,
          logIn: this.logIn,
          register: this.register,
          isAuthenticated: this.isAuthenticated,
          isApproved: this.isApproved,
          isUnauthenticated: this.isUnauthenticated,
          toggleDebugging: this.toggleDebugging,
          debuggingIsOn: this.debuggingIsOn,
          user,
          message,
          lastLoginFailed,
          lastRegFailed,
          lastRegSucceeded,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export const UserConsumer = UserContext.Consumer

export const WithUser = Component => {
  return function ComponentWithUser(props) {
    return <UserConsumer>{user => <Component {...props} user={user} />}</UserConsumer>
  }
}

export class Debug extends Component {
  state = {
    show: false,
  }
  toggle = () => {
    this.setState({ show: !this.state.show })
  }
  render() {
    const { value, name, children } = this.props
    const { show } = this.state
    return (
      <UserConsumer>
        {user => {
          if (user.debuggingIsOn && user.debuggingIsOn()) {
            return (
              <span className="debug">
                {' '}
                <button onClick={this.toggle}>
                  <code>
                    <b>i</b> {name}
                  </code>
                </button>{' '}
                {show && value ? (
                  <pre>
                    <code>{JSON.stringify(value, null, 4)}</code>
                  </pre>
                ) : null}
              </span>
            )
          } else {
            return null
          }
        }}
      </UserConsumer>
    )
  }
}

export function DebugUser() {
  return <UserConsumer>{user => <Debug value={user} name={'user'} />}</UserConsumer>
}
