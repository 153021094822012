import React from 'react'

// import { Debug } from '../../contexts/UserContext'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib } from '@fortawesome/fontawesome-svg-core'
import { faRedo, faStepForward, faStepBackward } from '@fortawesome/free-solid-svg-icons'
fLib.add(faRedo, faStepForward, faStepBackward)

export default function PlayerBreadcrumbs({ blocks, path, goToPath, blockIdCanPlay }) {
  return (
    <div className="path">
      {path.map((breadcrumb, index, path) => {
        if (!index) return null

        const { blockId, repNum } = breadcrumb
        const block = blocks[blockId]
        const pathToParent = path.slice(0, index)
        const parent = blocks[path[index - 1].blockId]
        const eligibleSiblings = parent.children.filter(blockIdCanPlay)
        const blockIndex = eligibleSiblings.indexOf(blockId)

        const antecedentId = eligibleSiblings[blockIndex - 1]
        const subsequentId = eligibleSiblings[blockIndex + 1]
        const pathToCurrent = pathToParent.concat({ blockId })
        const pathToAntecedent = pathToParent.concat({ blockId: antecedentId })
        const pathToSubsequent = pathToParent.concat({ blockId: subsequentId })
        const antecedent = antecedentId ? blocks[antecedentId] : false
        const subsequent = subsequentId ? blocks[subsequentId] : false

        return (
          <div key={blockId} className="breadcrumb">
            <div className="antecedent">
              {antecedent ? (
                <button onClick={() => goToPath(pathToAntecedent)}>
                  {antecedent.description} <Icon icon="step-backward" />
                </button>
              ) : (
                <button disabled>.</button>
              )}
            </div>
            <div className="current">
              <button onClick={() => goToPath(pathToCurrent)}>
                <Icon icon="redo" /> {block.description}
              </button>
              {block.numReps > 1 ? (
                <span className="counter">
                  {' '}
                  ({1 + (repNum || 0)} of {block.numReps})
                </span>
              ) : null}
            </div>
            <div className="subsequent">
              {subsequent ? (
                <button onClick={() => goToPath(pathToSubsequent)}>
                  <Icon icon="step-forward" /> {subsequent.description}
                </button>
              ) : (
                <button disabled>.</button>
              )}
            </div>
          </div>
        )
      })}
      {/*end path*/}
      <div style={{ clear: 'both' }} />
    </div>
  )
}
