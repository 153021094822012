import React, { Component } from 'react'
import { WithUser } from '../../contexts/UserContext'
class RegistrationForm extends Component {
  state = {
    username: '',
    email: '',
    password: '',
  }
  register = () => {
    const { username, email, password } = this.state
    this.props.user.register({ username, email, password })
  }

  handleUsernameChange = e => {
    this.setState({ username: e.target.value })
  }
  handlePasswordChange = e => {
    this.setState({ password: e.target.value })
  }
  handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }
  handleInputKeypress = e => {
    if (e.key === 'Enter') {
      this.register()
    }
  }

  render() {
    const { username, password, email } = this.state
    const { user } = this.props

    return (
      <div className="registrationForm">
        <h1>Create an account</h1>
        <input
          placeholder="username"
          value={username}
          type="text"
          autoCorrect="off"
          autoCapitalize="none"
          onChange={this.handleUsernameChange}
          onKeyPress={this.handleInputKeypress}
        />{' '}
        <input
          placeholder="email"
          value={email}
          onChange={this.handleEmailChange}
          onKeyPress={this.handleInputKeypress}
        />{' '}
        <input
          placeholder="password"
          value={password}
          type="password"
          onChange={this.handlePasswordChange}
          onKeyPress={this.handleInputKeypress}
        />{' '}
        <button onClick={this.register}>Create an account</button>
        <br />
        <br />
        {user && user.lastRegFailed && (
          <div className="warning">
            There was a problem with your registration. Please be sure that you are not
            using the same username or email as another account you have created, and that
            you are using an email address with a valid format.
          </div>
        )}
        {user && user.lastRegSucceeded && (
          <div className="happy">
            <h2>Your registration was successful</h2>
            <h3>
              The next step is for you to be approved to use the app. Please contact
              Daniel Schulz-Jackson to request approval. (There is no need to log in until
              approved.)
            </h3>
            <h3>
              By using this app, you agree not to disclose its concept or design to others
              without permission.
            </h3>
          </div>
        )}
        Please note that there is no &ldquo;Reset password&rdquo; feature yet... so please
        write down (on paper) or carefully memorize your password.
      </div>
    )
  }
}

export default WithUser(RegistrationForm)
