import React, { useState, useEffect } from 'react'

const intervalSeconds = 1

export default function DurationPlayer({ block, playing, goToNextPhase }) {
  const { pauseDur, completesManually } = block

  const [secondsElapsed, setSecondsElapsed] = useState(0)

  function incrementLater() {
    return setTimeout(() => {
      setSecondsElapsed(secondsElapsed + intervalSeconds)
    }, 1000 * intervalSeconds)
  }

  useEffect(() => {
    // if (completesManually || !pauseDur) {
    //   goToNextPhase()
    // }
    if (playing) {
      var timeoutId = incrementLater()
    }
    if (secondsElapsed >= pauseDur) {
      goToNextPhase()
    }
    if (timeoutId) {
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [playing, secondsElapsed])

  return (
    <div className="duration">
      {pauseDur ? (
        <div>
          Duration elapsed:
          <h1>
            {secondsElapsed} / {pauseDur}
          </h1>
        </div>
      ) : null}
    </div>
  )
}
