import MainAudioPlayer from '../components/phases/MainAudioPlayer'
import DurationPlayer from '../components/phases/DurationPlayer'
import ConditionalsPlayer from '../components/phases/ConditionalsPlayer'
import ChildrenPlayer from '../components/phases/ChildrenPlayer'
import SubroutinePlayer from '../components/phases/SubroutinePlayer'
import DummyPlayer from '../components/phases/DummyPlayer'
import ManualCompletionPlayer from '../components/phases/ManualCompletionPlayer'

const repPhases = [
  {
    name: 'conditionals',
    description: 'Conditionals',
    playerComponent: ConditionalsPlayer,
  },
  {
    name: 'mainAudio',
    description: 'Main Audio',
    playerComponent: MainAudioPlayer,
  },
  {
    name: 'manualCompletion',
    description: 'Completion',
    playerComponent: ManualCompletionPlayer,
  },
  {
    name: 'duration',
    description: 'Duration',
    playerComponent: DurationPlayer,
  },
  {
    // NOTE: children can't be last because the last needs to be a "normal" phase so that after, we'll hit goToNextBlock().
    name: 'children',
    description: 'Children',
    playerComponent: ChildrenPlayer,
  },
  {
    // NOTE: children can't be last because the last needs to be a "normal" phase so that after, we'll hit goToNextBlock().
    name: 'subroutine',
    description: 'Subroutine',
    playerComponent: SubroutinePlayer,
  },
  {
    // NOTE: children can't be last because the last needs to be a "normal" phase so that after, we'll hit goToNextBlock().
    name: 'needfulDummy',
    description: 'Needful Dummy',
    playerComponent: DummyPlayer,
  },
]

/////////

repPhases.findByName = phaseName => {
  return repPhases.find(phase => phaseName === phase.name)
}

repPhases.getFirstPhaseName = () => {
  return repPhases[0].name
}

repPhases.getSubsequentPhaseName = phaseName => {
  const phaseIndex = repPhases.findIndex(phase => phaseName === phase.name)
  const phase = repPhases[phaseIndex + 1]
  if (phase) {
    return phase.name
  } else {
    return false
  }
}

export default repPhases
