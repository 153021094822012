// TODO: Probably abstract away all API/IO from Block (it will be BuilderBlock.js ... into a BlocksStore or something... because we'll have a different view for PlayerBlock potentially...)

import React from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
// eventually we'll want this: https://github.com/jsierles/react-native-audio

import { Debug } from '../../contexts/UserContext'
import { WithBuilder } from '../../contexts/BuilderContext'
import { WithPlayer } from '../../contexts/PlayerContext'

import BlocksContainer from './BlocksContainer' //NOTE: this recursion breaks hot reloading, but only when editing this file, not others. even if the component is commented out. just importing breaks it. https://github.com/parcel-bundler/parcel/issues/177
import AudioRecorder from './AudioRecorder'
import SubroutineSelector from './SubroutineSelector'
import BlockSimpleInputs from './BlockSimpleInputs'
import { getTrackUrl } from '../../../../../shared/audioLocations'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib } from '@fortawesome/fontawesome-svg-core'
import {
  faStepForward,
  faTrash,
  faPlus,
  faFolderPlus,
  faArrowUp,
  faArrowDown,
  faReply,
  faTimes,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
fLib.add(
  faStepForward,
  faTrash,
  faPlus,
  faFolderPlus,
  faArrowUp,
  faArrowDown,
  faReply,
  faTimes,
  faSpinner
)

import headerGetters from '../../lib/headerGetters'

import { BACKEND_API_URL } from '../../lib/serverUrls'

// import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

const headers = headerGetters.withToken()

class Block extends React.Component {
  static propTypes = {
    _id: PropTypes.string.isRequired,
    index: PropTypes.number,
    depth: PropTypes.number.isRequired,
    ancestors: PropTypes.array.isRequired,
  }

  state = {
    showMore: false,
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // setTimeout(() => this.forceUpdate(), 3500) // moving a block currently always creates a new component (maybe there's a better way but if so I don't know it). So this will always clear justMoved class after a move.
  }

  getData = () => {
    const { _id, builder } = this.props
    return builder.state.blocks[_id]
  }

  setAndSaveState = async update => {
    /// TODO: RENAME
    const { _id, builder } = this.props
    builder.methods.relayBlockUpdate(_id, update)
  }

  relayEphemeralState = async update => {
    const { _id, builder } = this.props
    builder.methods.relayBlockState(_id, update)
  }

  delete = () => {
    const { _id, parentRemoveBlock, debug } = this.props
    const { children, audioFile } = this.getData()
    const confirmMsg = `Are you sure you want to delete this audio block${
      children && children.length ? ' and its children' : ''
    }${audioFile ? ' and its audio file' : ''}?`
    if (debug || confirm(confirmMsg)) {
      fetch(`${BACKEND_API_URL}/blocks/${_id}`, {
        // TODO: probably move me to the Builder...
        headers,
        method: 'DELETE', // TODO: if other blocks reference this audio, transfer it to them and don't delete the file.
      })
        .then(res => res.json())
        .then(res => {
          if ('block_deleted' == res.status) {
            parentRemoveBlock(_id)
          } else {
            console.error('block was not successfully deleted...')
          }
        })
    }
  }

  moveUp = () => {
    this.move('up')
  }
  moveDown = () => {
    this.move('down')
  }
  moveTo = () => {
    alert('coming soon...')
  }
  move = async direction => {
    await this.setAndSaveState({ lastMoved: Date.now() })
    this.props.moveInParent({ blockId: this.props._id, direction })
  }

  setChildren = async children => {
    this.relayEphemeralState({ derpyCreatedFirstChild: false })
    return await this.setAndSaveState({ children })
  }

  derpyCreateFirstChild = () => {
    this.relayEphemeralState({ derpyCreatedFirstChild: true })
  }

  setNumChildrenReps = numChildrenReps => {
    this.setAndSaveState({ numChildrenReps })
  }

  // I think... I think this would go into the Audio model/component once Audio has its own model.
  intakeAudioFile = audioFile => {
    const audioUrl = getTrackUrl(audioFile.filename)
    this.relayEphemeralState({ audioUrl, audioFile }) // don't need to save because the backend already saved this.
  }

  //////////////////////

  toggleShowMore = () => {
    const { showMore } = this.state
    this.setState({ showMore: !showMore })
  }

  intakeTemporaryAudioUrl = audioUrl => {
    this.setAndSaveState({ audioUrl })
  }
  intakeSubroutine = async subroutine => {
    const { builder } = this.props
    await this.setAndSaveState({ subroutine })
    builder.methods.refreshRoutinesBlocks()
  }

  simpleEventVal = e => {
    const { target } = e
    const val = target.value
    if ('checkbox' === target.type) {
      return target.checked
    } else if ('number' === target.type) {
      return Number(val)
    } else {
      return val
    }
  }
  SimpleE = e => {
    const name = e.target.name
    return { val: this.simpleEventVal(e), name, dirtyName: `${name}_dirty` }
  }
  handleSimpleChange = e => {
    const simpleE = new this.SimpleE(e)
    this.relayEphemeralState({ [simpleE.name]: simpleE.val, [simpleE.dirtyName]: true })
  }
  handleSimpleBlur = e => {
    const simpleE = new this.SimpleE(e)
    if (true === this.getData()[simpleE.dirtyName]) {
      // todo: make sure this conditional ^ is actually working...
      this.relayEphemeralState({ [simpleE.dirtyName]: false })
      this.setAndSaveState({ [simpleE.name]: simpleE.val })
    }
  }

  render() {
    const { builder, player, depth, debug, _id, ancestors } = this.props
    const ancestorsOfChildren = ancestors.slice()
    ancestorsOfChildren.unshift(_id)

    const blockData = builder.state.blocks[_id]
    if (!blockData)
      return (
        <div className="block">
          <Icon icon="spinner" spin />
          no blockData
        </div>
      )

    const {
      audioUrl,
      audioVolume,
      backgroundVideoUrl,
      children,
      completesManually,
      contentVideoUrl,
      description,
      numChildrenReps,
      numReps,
      pauseDur,
      routine,
      subroutine,
    } = blockData

    const { showMore } = this.state

    if (!routine) {
      return (
        <div className="block loading">
          <Icon icon="spinner" spin />
          no routine
          <Debug value={{ props: this.props, state: this.state, data: this.getData() }} />
        </div>
      )
    }

    return (
      <div
        className={`block depth-${depth} ${classNames({
          justMoved: Date.now() - this.getData().lastMoved < 3000,
          zeroReps: 0 === numReps,
          hasChildren: children.length,
          showMore,
        })}`}
        onMouseEnter={() => this.forceUpdate()}
      >
        <BlockSimpleInputs
          {...{
            description,
            numReps,
            pauseDur,
            audioVolume,
            completesManually,
            backgroundVideoUrl,
            contentVideoUrl,
          }}
          handleSimpleChange={this.handleSimpleChange}
          handleSimpleBlur={this.handleSimpleBlur}
        />{' '}
        {/* TODO: if <Audio/> was its own model, which really perhaps it should be, then I wouldn't be bloating Block with so much data-passthrough. This would just have _id={audioId},*/}
        <AudioRecorder
          postUrl={`${BACKEND_API_URL}/blocks/${_id}/track`}
          relayAudioFileInfo={this.intakeAudioFile}
          relayTemporaryAudioUrl={this.intakeTemporaryAudioUrl}
        />{' '}
        {/* eslint-disable-next-line */}
        {audioUrl ? <audio controls src={audioUrl} /> : null}{' '}
        <span>
          <button onClick={this.moveUp} className="moveUp" title="Move this block up">
            <Icon icon="arrow-up" />
          </button>
        </span>{' '}
        <span>
          <button
            onClick={this.moveDown}
            className="moveDown"
            title="Move this block down"
          >
            <Icon icon="arrow-down" />
          </button>
        </span>{' '}
        <span>
          {_id === builder.state.movingBlockId ? (
            <button
              onClick={() => builder.methods.clearBlockMove()}
              className="cancelMove"
              title="Cancel moving this block"
            >
              <Icon icon="times" /> Cancel Move
            </button>
          ) : (
            <button
              onClick={async () => {
                await this.setAndSaveState({ lastMoved: Date.now() })
                builder.methods.initializeBlockMove({ movingBlockId: _id })
              }}
              className="startMove"
              title="Move this block to a new location"
            >
              <Icon icon="reply" />
            </button>
          )}
        </span>{' '}
        {!numReps && children.length ? (
          <span>
            <button onClick={this.toggleShowMore} className="toggleShowMore">
              <b>{showMore ? <Icon icon="times" /> : '...'}</b>
            </button>
          </span>
        ) : null}{' '}
        {depth && (numReps || showMore || !children.length) ? (
          <span>
            <button
              onClick={this.delete}
              className="deleteBlock"
              title="Delete this block"
            >
              <Icon icon="trash" />
            </button>
          </span>
        ) : null}{' '}
        {this.getData().derpyCreatedFirstChild || (children && children.length) ? null : (
          <button className="derpyCreateFirstChild" onClick={this.derpyCreateFirstChild}>
            <Icon icon="folder-plus" />
          </button>
        )}{' '}
        <Debug
          value={{
            props: Object.assign({}, this.props, {
              builder: 'REDACTED',
            }),
            state: this.state,
            blockData,
          }}
        />
        <SubroutineSelector
          parentRoutine={routine}
          subroutine={subroutine}
          relaySubroutine={this.intakeSubroutine}
        />{' '}
        <button
          onClick={() =>
            player.goTo(
              ancestors
                .slice()
                .reverse()
                .concat(_id),
              true
            )
          }
        >
          <Icon icon="step-forward" />
        </button>
        {/* &lt; {ancestors.map(aId => builder.state.blocks[aId].description).join(' < ')} */}
        {this.getData().derpyCreatedFirstChild || (children && children.length) ? (
          <BlocksContainer
            name={description}
            blocks={children}
            initialNumReps={numChildrenReps}
            parentId={_id}
            parentSetChildren={this.setChildren /*todo:remove*/}
            depth={depth + 1}
            debug={debug}
            routine={routine}
            ancestors={ancestorsOfChildren}
          />
        ) : null}{' '}
      </div>
    )
  }
}

export default WithPlayer(WithBuilder(Block))
