import React from 'react'
import { Link } from 'react-router-dom'

import { WithUser } from '../../contexts/UserContext'

const AuthRequired = ({ user, children }) => {
  const { isAuthenticated, isUnauthenticated } = user

  if (isAuthenticated()) {
    return <div className="authRequired">{children}</div>
  } else if (isUnauthenticated()) {
    return <UnAuthMessage />
  } else {
    return <AuthUndeterminedMessage />
  }
}

////////////////////

export const UnAuthMessage = () => (
  <div>
    <h2>
      <Link to="/login">Go to log in &gt;&gt;</Link>
    </h2>
  </div>
)

export const AuthUndeterminedMessage = () => (
  <div>
    <h2>This content will load once we confirm your logged-in status.</h2>

    <h4>(If this takes more than a couple seconds... then something is likely broken on our end.)</h4>

    <h2>
      <Link to="/login">Optional: Go to log in &gt;&gt;</Link>
    </h2>
  </div>
)

export default WithUser(AuthRequired)
