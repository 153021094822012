import React, { useState, useEffect } from 'react'
import NoSleep from 'nosleep.js'

import { PlayerProvider } from '../../contexts/PlayerContext'
import { WithBuilder } from '../../contexts/BuilderContext'
import { Debug } from '../../contexts/UserContext'

import PlayerBreadcrumbs from './PlayerBreadcrumbs'
import repPhases from '../../lib/repPhases'
import VideoEmbed from './VideoEmbed'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib } from '@fortawesome/fontawesome-svg-core'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'
import { WithRoutine } from '../../contexts/RoutineContext'
fLib.add(faPlay, faPause)

const defaultBackgroundVideoUrl = 'https://www.youtube.com/watch?v=XIMLoLxmTDw'

function Player({ children, builder, routine }) {
  const { blocks } = builder.state
  const { routines } = builder.props

  /// JUST METHODS:

  function makePath({ rawPath }) {
    return rawPath.map(blockId => makeCrumb({ blockId, repNum: 1, repPhase: 'children' }))
  }
  function makeCrumb({ blockId, repNum, repPhase }) {
    console.assert(blockId)
    return {
      blockId,
      repNum: repNum || 0,
      repPhase: repPhase || false,
    }
  }
  function pathCurrentCrumb(path) {
    return path[path.length - 1]
  }
  function parentCrumb(path) {
    return path[path.length - 2]
  }
  function pathPathToParent(path) {
    return path.slice(0, path.length - 1)
  }

  function pathCurrentBlock(path) {
    const blockId = pathCurrentCrumb(path) && pathCurrentCrumb(path).blockId
    return blocks[blockId]
  }
  function pathParentBlock(path) {
    const blockId = parentCrumb(path) && parentCrumb(path).blockId
    return blocks[blockId]
  }

  function pathLog(/* path */) {
    // const block = pathCurrentBlock(path)
    // const crumb = pathCurrentCrumb(path)
    // console.info(
    //   'path: ...[',
    //   path.length - 1,
    //   ']... ',
    //   `"${block.description}"`,
    //   crumb.repNum,
    //   crumb.repPhase
    // )
  }

  ///////

  function setCurrentPhase(repPhase) {
    const newCrumb = Object.assign(crumb, { repPhase })
    const newPath = pathPathToParent(path).concat(newCrumb)
    goToPath(newPath)
  }

  function setCurrentRepNum(repNum) {
    const newCrumb = Object.assign(crumb, { repNum, repPhase: false })
    const newPath = pathPathToParent(path).concat(newCrumb)
    goToPath(newPath)
  }

  /////// LOOP FUNCS:

  function blockIdCanPlay(blockId) {
    return !!blocks[blockId].numReps
  }

  function goToPath(path, doPlay) {
    pathLog(path)
    const newState = { ...state, path }
    if (doPlay) newState.playing = true
    setState(newState)
  }

  function goToFirstChild() {
    const block = pathCurrentBlock(path)
    goToPath(path.concat(makeCrumb({ blockId: block.children[0] })))
  }
  function goToSubroutine() {
    const block = pathCurrentBlock(path)
    const subroutine = routines.find(r => r._id === block.subroutine)
    if (path.map(p => p.blockId).includes(subroutine.rootBlock)) {
      goToNextPhase()
    } else {
      goToPath(path.concat(makeCrumb({ blockId: subroutine.rootBlock })))
    }
  }
  function goToParentNextPhase() {
    const pathToParent = pathPathToParent(path)
    const parentCrumb = pathCurrentCrumb(pathToParent)
    const nextPhase = repPhases.getSubsequentPhaseName(parentCrumb.repPhase)
    const newParentCrumb = Object.assign(parentCrumb, { repPhase: nextPhase })
    pathToParent[pathToParent.length - 1] = newParentCrumb
    goToPath(pathToParent)
  }

  function goToNextPhase() {
    const crumb = pathCurrentCrumb(path)
    const nextPhase = repPhases.getSubsequentPhaseName(crumb.repPhase)
    if (nextPhase) {
      setCurrentPhase(nextPhase)
    } else {
      goToNextRep()
    }
  }

  function goToNextRep() {
    const block = pathCurrentBlock(path)
    const crumb = pathCurrentCrumb(path)
    const nextRepNum = crumb.repNum + 1
    if (nextRepNum + 1 <= block.numReps) {
      setCurrentRepNum(nextRepNum)
    } else {
      goToNextBlock()
    }
  }

  function goToNextBlock() {
    const parentBlock = pathParentBlock(path)
    if (parentBlock) {
      const indexInParent = parentBlock.children.indexOf(block._id)
      const nextSiblingId = parentBlock.children[indexInParent + 1]
      const pathToParent = pathPathToParent(path)

      if (nextSiblingId) {
        const siblingPath = pathToParent.concat(makeCrumb({ blockId: nextSiblingId }))
        goToPath(siblingPath)
      } else {
        goToParentNextPhase()
      }
    } else {
      setState({ playing: false, path: [] })
    }
  }

  // ACTION FUNCS:

  function togglePlay() {
    if (playing) {
      pause()
    } else {
      play()
    }
  }

  function play() {
    noSleep && noSleep.enable()
    const newState = { ...state, playing: true }
    if (!newState.path.length) {
      newState.path = [makeCrumb({ blockId: builder.props.rootBlockId })]
    }
    setState(newState)
  }

  function pause() {
    noSleep.disable()
    setState({ ...state, playing: false })
  }

  function goTo(rawPath, doPlay) {
    const path = makePath({ rawPath })
    const crumb = pathCurrentCrumb(path)
    crumb.repNum = 0
    crumb.repPhase = false
    goToPath(path, doPlay)
  }

  /// ACTUAL RUNTIME:

  const [state, setState] = useState({
    playing: false,
    path: [],
    noSleep: new NoSleep(),
    backgroundVideosEnabled: true,
    // audioPlayPromise: null, // I think we're gonna do this in the phase-player-component(s)
  })

  const { path, playing, noSleep, backgroundVideosEnabled } = state
  if (path.length) {
    var block = pathCurrentBlock(path)
    var crumb = pathCurrentCrumb(path)
  }

  useEffect(() => {
    if (routine && routine.autoplay) {
      setTimeout(play, 1000) // gotta let the top block load
    }
  }, [routine])

  useEffect(() => {
    // I like the idea that the "goTos help us navigate (change path), and then any time we get a new path, we check"
    if (crumb) {
      if (!crumb.repNum) {
        // goToNextRep()???
      }
      if (!crumb.repPhase) {
        if (playing) {
          setCurrentPhase(repPhases.getFirstPhaseName())
        }
      }
    }
  }, [path, playing]) // note: I believe for path to trigger changes here, path must be .slice()'d into a new copy before setState(), which I believe is a best practice anyway.

  const repPhase = repPhases.findByName(crumb && crumb.repPhase)

  const currentBackgroundVideoUrl = path.reduce((deepestUrl, crumb) => {
    const block = blocks[crumb.blockId]
    const { backgroundVideoUrl } = block
    deepestUrl = backgroundVideoUrl || deepestUrl
    return deepestUrl
  }, null)

  function DummyComponent() {
    return <div></div>
  }
  const PhaseComponent = (repPhase && repPhase.playerComponent) || DummyComponent
  return (
    <div id="player">
      <Debug value={state} name="player" />
      <Debug
        value={path.map(bc => blocks[bc.blockId].description)}
        name="rawPathPretty"
      />
      <Debug value={builder} />
      <button onClick={togglePlay} style={{ fontSize: 50 }}>
        {playing ? <Icon icon="pause" /> : <Icon icon="play" />}
      </button>{' '}
      <label>
        <input
          type="checkbox"
          checked={backgroundVideosEnabled}
          onChange={() =>
            setState({ ...state, backgroundVideosEnabled: !backgroundVideosEnabled })
          }
        />
        Bkg Vids
      </label>
      {backgroundVideosEnabled && (
        <div style={{ width: 320, height: 180 }}>
          <VideoEmbed
            videoUrl={playing && (currentBackgroundVideoUrl || defaultBackgroundVideoUrl)}
          />
        </div>
      )}
      <PlayerBreadcrumbs {...{ blocks, path, goToPath, blockIdCanPlay }} />
      <div className="content">
        <PhaseComponent
          {...{
            block,
            playing,
            goToNextPhase,
            goToSubroutine,
            goToFirstChild,
            goToNextRep,
            goToNextBlock,
          }}
        />
      </div>
      <PlayerProvider value={{ ...{ state, togglePlay, goTo } }}>
        {children}
      </PlayerProvider>
    </div>
  )
}

export default WithRoutine(WithBuilder(Player))
