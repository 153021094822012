import React from 'react'

export default function SubroutinePlayer({ block, goToNextPhase, goToSubroutine }) {
  if (block.subroutine) {
    goToSubroutine()
  } else {
    goToNextPhase()
  }
  return null
}
