import React from 'react'
import { render } from 'react-dom'
import HttpsRedirect from 'react-https-redirect'

import { UserProvider } from './contexts/UserContext'

import Index from './components/Index'

import './main.scss'

class App extends React.Component {
  render() {
    return (
      <HttpsRedirect>
        <UserProvider>
          <div id="app">
            <Index />
          </div>
        </UserProvider>
      </HttpsRedirect>
    )
  }
}

export default App

render(<App />, document.getElementById('root'))
