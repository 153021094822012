
function addToken(headers) {
    const token = localStorage.getItem('token')
    if (null == token || "undefined" == token) {
        // no token found
    } else {
        headers.Authorization = `Bearer ${token}` ///////// WAIT A MINUTE, HOW WAS TOKEN VERIFY WORKING???
    }
}

function copy(obj) {
    return Object.assign({}, obj)
}

const baseHeaders = {
    'Content-Type': 'application/json',
    'Accepts': 'application/json'
}

//////////// EXPORTS:

function base() { // for unauthenticated calls, but the naming is as much for internal use in this class as a base for naming convention of headers.
    return copy(baseHeaders)
}

function withToken() {
    const headers = copy(baseHeaders)
    addToken(headers)
    return headers
}



function withTokenAndNoContentType() {  // don't need content type on POST to multer - https://stackoverflow.com/a/37780167/1417853
    const headers = copy(baseHeaders)
    delete headers['Content-Type']
    addToken(headers)
    return headers
}

// function formWithToken() {
//     const headers = {}
//     // const headers = copy(baseHeaders)
//     // headers['Content-Type'] = 'multipart/form-data; boundary=something'
//     /* boundary
//     For multipart entities the boundary directive is required, which consists of 1 to 70 characters from a set of characters known to be very robust through email gateways, and not ending with white space. It is used to encapsulate the boundaries of the multiple parts of the message. Often the header boundary is prepended by two dashes in the body and the final boundary also have a two dashes appended to it.
//     */
//     addToken(headers)
//     return headers
// }
// function multipartFormDataWithToken() {
//     const headers = copy(baseHeaders)
//     headers['Content-Type'] = 'multipart/form-data'
//     addToken(headers)
//     return headers
// }

export default {
    base,
    withToken,
    // formWithToken
    // multipartFormDataWithToken,
    withTokenAndNoContentType
}