import React from 'react'
import RepX from '../common/RepX'
import { Debug } from '../../contexts/UserContext'

const t = t => t

const blurOnEnterKey = e => {
  if (e.key === 'Enter') {
    e.target.blur() // e.currentTarget.blur() // maybe use currentTarget too if it's not working in some context; source: https://stackoverflow.com/questions/41225739/event-target-blur-not-working
  }
}

export default function BlockSimpleInputs({
  numReps,
  description,
  pauseDur,
  audioVolume,
  backgroundVideoUrl,
  completesManually,

  handleSimpleBlur,
  handleSimpleChange,
  // contentVideoUrl,
}) {
  return (
    <span className="simpleInputs">
      <input
        name="numReps"
        className="numReps"
        value={numReps || ''}
        onChange={handleSimpleChange}
        onBlur={handleSimpleBlur}
        onKeyDown={blurOnEnterKey}
        placeholder="0"
        type="number"
      />{' '}
      <RepX />
      <input
        name="description"
        className="description"
        value={description}
        onChange={handleSimpleChange}
        onBlur={handleSimpleBlur}
        onKeyDown={blurOnEnterKey}
        type="text"
        placeholder={t('Block Name')}
      />{' '}
      <input
        name="pauseDur"
        className="pauseDur"
        value={pauseDur || ''}
        onChange={handleSimpleChange}
        onBlur={handleSimpleBlur}
        onKeyDown={blurOnEnterKey}
        type="number"
        placeholder="⏸️️"
      />{' '}
      <input
        name="backgroundVideoUrl"
        className="backgroundVideoUrl"
        value={backgroundVideoUrl || ''}
        onChange={handleSimpleChange}
        onBlur={handleSimpleBlur}
        onKeyDown={blurOnEnterKey}
        type="text"
        placeholder="Bkg Vid URL"
      />{' '}
      {/* <input
        name="contentVideoUrl"
        className="contentVideoUrl"
        value={contentVideoUrl || ''}
        onChange={handleSimpleChange}
        onBlur={handleSimpleBlur}
        onKeyDown={blurOnEnterKey}
        type="text"
        placeholder="Content Video URL"
      />{' '} */}
      <label className="completesManually">
        <span role="img" aria-label="complete manally" style={{ marginRight: -8 }}>
          👆
        </span>
        <input
          name="completesManually"
          className="completesManually"
          onChange={handleSimpleChange}
          onBlur={handleSimpleBlur}
          checked={completesManually}
          type="checkbox"
        />
      </label>
      <Debug
        name={
          <span>
            Vol{' '}
            <input
              style={{ width: '3em' }}
              name="audioVolume"
              className="audioVolume"
              value={audioVolume || ''}
              onChange={handleSimpleChange}
              onBlur={handleSimpleBlur}
              onKeyDown={blurOnEnterKey}
              type="number"
              placeholder="VOLUME"
            />
          </span>
        }
      />
    </span>
  )
}
