import React from 'react'

const PlayerContext = React.createContext()

export const PlayerProvider = PlayerContext.Provider

export const WithPlayer = Component => {
  return function ComponentWithPlayer(props) {
    return (
      <PlayerContext.Consumer>
        {player => <Component {...props} player={player} />}
      </PlayerContext.Consumer>
    )
  }
}
