import React from 'react'
import { Link } from 'react-router-dom'
import AuthRequired from './common/AuthRequired'

const { APP_DISPLAY_NAME } = process.env
const t = t => t

export default class HomePage extends React.Component {
  componentDidMount() {
    document.title = `${t('Home')} | ${APP_DISPLAY_NAME}`
  }
  render() {
    return (
      <div className="home">
        <AuthRequired>
          <Link to="/routines">
            <h3>{t('Go to My Routines >>')}</h3>
          </Link>
        </AuthRequired>
      </div>
    )
  }
}
