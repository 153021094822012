import React, { useRef, useEffect } from 'react'
import AudioPlayerSimple from '../builder/AudioPlayerSimple'

export default function MainAudioPlayer({ block, playing, goToNextPhase }) {
  block = block || {}

  const audioPlayerRef = useRef()

  useEffect(() => {
    if (playing) {
      /* audioPlayerRef.current && (we'll see if its needed) */
      audioPlayerRef.current.play().then(goToNextPhase) // SMELL: this might trigger multiple times... various possible ways to solve this including having endPhase store what phase will be ended...
    } else {
      /* audioPlayerRef.current && (we'll see if its needed) */
      audioPlayerRef.current.pause()
    }
  }, [playing])

  return (
    <div className="mainAudio">
      <AudioPlayerSimple
        ref={audioPlayerRef}
        audioUrl={block.audioUrl}
        audioVolume={block.audioVolume}
        description={block.description}
      />
    </div>
  )
}
