import React, { Component } from 'react'

import { WithUser, Debug } from '../contexts/UserContext'

import AuthRequired from './common/AuthRequired'
import { UnapprovedMessage } from './common/ApprovalRequired'

const { APP_DISPLAY_NAME } = process.env

class AccountPage extends Component {
  componentDidMount() {
    document.title = `Account | ${APP_DISPLAY_NAME}`
  }
  render() {
    const { user: userData, message, logOut, isApproved } = this.props.user
    const { username, email } = userData
    return (
      <div>
        <AuthRequired>
          <h1>Account</h1>
          <br />
          username: <b>{username}</b>
          <br />
          <br />
          email: <b>{email}</b>
          <br />
          <br />
          <br />
          <Debug value={{ userData, message }} />
          <button onClick={logOut}>Log out</button>
          {!isApproved || !isApproved() ? (
            <div>
              <br />
              <hr />
              <UnapprovedMessage />
            </div>
          ) : null}
        </AuthRequired>
      </div>
    )
  }
}

export default WithUser(AccountPage)
