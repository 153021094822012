import React from 'react'

const RoutinesContext = React.createContext()

const RoutinesConsumer = RoutinesContext.Consumer

export const RoutinesProvider = RoutinesContext.Provider

export const WithRoutines = Component => {
  return function ComponentWithRoutines(props) {
    return (
      <RoutinesConsumer>
        {routines => <Component {...props} routines={routines} />}
      </RoutinesConsumer>
    )
  }
}
