import React from 'react'

import { WithUser } from '../../contexts/UserContext'

const ApprovalRequired = ({ user, children }) => {
    const { isApproved } = user

    if (isApproved && isApproved()) {
        return <div className="approvalRequired">{children}</div>
    } else {
        return <UnapprovedMessage />
    }
}

////////////////////

export const unapprovedMessage = `The next step is for you to be approved to use the app. 

Please contact Daniel Schulz-Jackson to request approval.

By using this app, you agree not to disclose its concept or design to others without permission.

(Once approval is granted, in order to use the app you will need to log out and log back in again.)

`//.replace("\n", `<br/>`)

export const UnapprovedMessage = () => (
    <div>
        <h2>
            {unapprovedMessage}
        </h2>
    </div>
)


export default WithUser(ApprovalRequired)