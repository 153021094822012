import React from 'react'
import { MoveHereButton } from './Destination'

export default function DestinationRoutines({
  movingBlockId,
  moveBlockToRoutine,
  routines,
  routineId,
}) {
  const otherActiveRoutines = routines
    .filter(routine => routine._id != routineId)
    .filter(routine => !routine.archivedOn)

  if (otherActiveRoutines.length) {
    return (
      <div className="destinationRoutines">
        <h3>move to routine:</h3>
        {otherActiveRoutines.map(routine => {
          return (
            <div className="destinationRoutine" key={routine._id}>
              <MoveHereButton
                onClick={async () => {
                  moveBlockToRoutine(movingBlockId, routine._id)
                }}
              />{' '}
              <strong>{routine.description}</strong>
            </div>
          )
        })}
      </div>
    )
  } else {
    return null
  }
}
