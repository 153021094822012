import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import { Debug } from '../contexts/UserContext'

import Builder from './builder/Builder'
import { RoutineProvider } from '../contexts/RoutineContext'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib } from '@fortawesome/fontawesome-svg-core'
import { faArchive, faTrash } from '@fortawesome/free-solid-svg-icons'
fLib.add(faArchive, faTrash)

const routineTemplate = {
  description: '',
  note: '',
  autoplay: true,
  createdOn: 0,
  archivedOn: 0,
  rootBlock: '',
}
export const makeRoutineFromData = function(routineData) {
  const dateFieldNames = ['createdOn', 'archivedOn']
  dateFieldNames.forEach(dateFieldName => {
    const dateText = routineData[dateFieldName]
    if (dateText) {
      routineData[dateFieldName] = Date.parse(dateText)
    }
  })
  return Object.assign({}, routineTemplate, routineData)
}

// const t = t => t
const { APP_DISPLAY_NAME } = process.env

// TODO: Give routines .order prop...
// TODO: Update rootBlock description to match routine (with "Routine" appended...)
// Or... MAYBE... no. We're not going to query the whole routines table on every keydown :) Although, I guess we could index on it and it'd be no worse than hitting it by Id... okay... so, I was going to say that the RootBlock's name is always getting passed up to the routine. That way, you can have it nice and big and pretty, on the rootBlock of the routine. It's just another block, but you increase the font size so that it looks like a big heading... but also still looks consistent with the other descriptions. It conveys something fundamentally (currently) true, that a Routine is basically just a top-block.
// Alternately, to keep the DB DRY, and to save DB hits, we could just have the routines in the Routines object always pull in their rootblock... (no, that's requiring weirdness... either extra DB calls.)
// Honestly, this whole thing with routines was in some way a farce; a "Routine" is just a block tagged as "I am a routine". find({owner:me, isRoutineRoot:true})
// LOL. that could have saved some time...
// although, Routine may want to be shared with other users. And we may log stats at the routine-level. And the Player may want to display a routine but not all its blocks... So maybe it's worth letting the Routine see a BlocksContext (I've already known I'll need a BlocksContext... and pull in the RootBlock (mostly to get the description, but also to get its Play functions, etc.!! yup. get its play functions. this is good. Then we can have cute Routine -play buttons, not constrained to the weirdness of having it all be on a block...))
class Routine extends Component {
  static defaultProps = {
    updateRoutineById: () => {
      console.warn('updateRoutineById defaultProp must be replaced by an actual prop')
    },
  }

  relayUpdate = update => {
    const { updateRoutineById, routine } = this.props
    updateRoutineById(routine._id, update)
  }
  relayDelete = () => {
    const { deleteRoutineById, routine } = this.props
    deleteRoutineById(routine._id)
  }

  handleDescriptionChange = e => {
    this.relayUpdate({ description: e.target.value })
  }
  handleNoteChange = e => {
    this.relayUpdate({ note: e.target.value })
  }
  handleRootBlockChange = e => {
    this.relayUpdate({ rootBlock: e.target.value })
  }

  // Stolen and simplified from Block // TODO: UNIFY
  handleSimpleChange = e => {
    this.relayUpdate({ [e.target.name]: this.simpleEventVal(e) })
  }
  simpleEventVal = e => {
    const { target } = e
    const val = target.value
    if ('checkbox' === target.type) {
      return target.checked
    } else if ('number' === target.type) {
      return Number(val)
    } else {
      return val
    }
  }

  archive = () => {
    // const inputText = prompt(
    //   `Type "archive" if you want to archive the following routine: ${
    //     this.props.routine.description
    //   }`
    // )
    // if ('archive' === inputText) {
    this.relayUpdate({ archivedOn: Date.now() })
    // } else {
    //   alert('archiving canceled')
    // }
  }
  unarchive = () => {
    this.relayUpdate({ archivedOn: 0 })
  }

  delete = () => {
    if (confirm('Are you sure you want to delete this routine and all its contents?')) {
      this.relayDelete()
    }
  }

  render() {
    // https://www.youtube.com/watch?v=nTXeWIL1vhA

    const { routine } = this.props
    const {
      _id,
      description,
      createdOn,
      archivedOn,
      note,
      rootBlock,
      autoplay,
    } = routine || {
      ...routineTemplate,
      foo: console.warn('routine is falsy...'), //  TODO: LOL wut?
    }

    return (
      <div className={`routine${archivedOn ? ' archived' : ''}`}>
        <Helmet>
          <title>
            {description} | {APP_DISPLAY_NAME}
          </title>
        </Helmet>
        <Debug value={routine} />
        <label className="descriptionLabel">
          Name:{' '}
          <input
            className="description"
            onChange={this.handleDescriptionChange}
            value={description}
            placeholder="Routine Name"
          />
          <sub> (created on {new Date(createdOn).toLocaleDateString('en-US')})</sub>{' '}
        </label>
        {archivedOn ? (
          <p style={{ fontSize: '1.5em' }}>
            <b>(Archived on {new Date(archivedOn).toLocaleDateString('en-US')})</b>{' '}
            <button onClick={this.unarchive}>Re-activate</button>
          </p>
        ) : (
          <button onClick={this.archive} title="Archive this Routine">
            <Icon icon="archive" />
          </button>
        )}{' '}
        <button onClick={this.delete} title="Delete this Routine">
          <Icon icon="trash" />
        </button>{' '}
        <label className="autoplay">
          <input
            name="autoplay"
            className="autoplayInput"
            onChange={this.handleSimpleChange}
            checked={autoplay}
            type="checkbox"
          />
          Autoplay
        </label>
        <br />
        <label className="noteLabel">
          <br />
          Notes:{' '}
          <textarea
            className="note"
            onChange={this.handleNoteChange}
            value={note}
            placeholder="Any notes you like may go here..."
          />
          <br />
        </label>
        {/* <Debug>
          Root Block ID:{' '}
          <input
            className="rootBlock"
            onChange={this.handleRootBlockChange}
            value={rootBlock}
            placeholder="Root Block ID"
          />
          <br />
        </Debug> */}
        <br />
        <RoutineProvider value={routine}>
          <Builder routineId={_id} rootBlockId={rootBlock} name={description} />
        </RoutineProvider>
      </div>
    )
  }
}

export default Routine
