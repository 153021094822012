import React from 'react'
import AuthRequired from './common/AuthRequired'

const { APP_DISPLAY_NAME } = process.env
const t = t => t

export default class PlayerPage extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.title = `${t('Player')} | ${APP_DISPLAY_NAME}`
  }
  render() {
    return (
      <div className="player">
        <AuthRequired>welcome to player</AuthRequired>
      </div>
    )
  }
}
