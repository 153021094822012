import React from 'react'

export default function VideoEmbed({ videoUrl }) {
  let atts = null

  if (videoUrl) {
    if (videoUrl.match(/youtu/)) {
      const ytId = getYouTubeId(videoUrl)
      atts = {
        src: `https://www.youtube.com/embed/${ytId}?autoplay=1`,
        allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
        // allowFullScreen: true
      }
    }
  } else {
    return <div className="videoEmbed empty"></div>
  }

  return (
    <div className="videoEmbed">
      {atts ? (
        <iframe
          title={`backgroundVideo: ${videoUrl}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          {...atts}
        ></iframe>
      ) : (
        <div>sorry, video url {videoUrl} is not supported</div>
      )}
    </div>
  )
}

function getYouTubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  const ytId = match && match[2].length === 11 ? match[2] : null
  return ytId
}
