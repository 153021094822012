import React, { useState } from 'react'
import { WithRoutines } from '../../contexts/RoutinesContext'

export default WithRoutines(function SubroutineSelector({
  parentRoutine,
  relaySubroutine,
  routines,
  subroutine,
}) {
  const [opened, setOpened] = useState(false)
  return (
    <span className="SubroutineSelector">
      <button onClick={() => setOpened(true)}>Sub</button>{' '}
      {opened || subroutine ? (
        <select
          defaultValue={subroutine}
          onBlur={event => {
            relaySubroutine(event.target.value)
          }}
        >
          <option></option>
          {routines
            .filter(routine => routine._id != parentRoutine)
            .filter(routine => !routine.archivedOn)
            .map(routine => (
              <option key={routine._id} value={routine._id}>
                {routine.description}
              </option>
            ))}
        </select>
      ) : null}
    </span>
  )
})
