import React from 'react'

export const BuilderContext = React.createContext()

const BuilderConsumer = BuilderContext.Consumer

export const WithBuilder = Component => {
  return function ComponentWithBuilder(props) {
    return (
      <BuilderConsumer>
        {builder => <Component {...props} builder={builder} />}
      </BuilderConsumer>
    )
  }
}
