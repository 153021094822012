import React from 'react'

const { APP_DISPLAY_NAME } = process.env
const t = t => t

export default class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    document.title = `${t('Page Not Found')} | ${APP_DISPLAY_NAME}`
  }
  render() {
    return <div className="not-found">... not found ...</div>
  }
}
