import React from 'react'

export default function ConditionalsPlayer({ block, goToNextBlock, goToNextPhase }) {
  if (!block.numReps) {
    goToNextBlock()
  } else {
    goToNextPhase()
  }
  return <div className="conditionals"></div>
}
