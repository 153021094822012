require('dotenv').config()

const { STORAGE_API_URL } = process.env

// Extensions
const tracksExt = '.mp3'

// Slugs
const originalsSlug = 'originals'
const tracksSlug = 'tracks'

// Disk-paths
const originalsPathFromRoot = `data/${originalsSlug}` /// YES; these are the same; yes; I moved originals back into Tracks... so both originals and tracks will be served...
function getOriginalPath(trackName) {
  return `${originalsPathFromRoot}/${trackName}`
}
const tracksPathFromRoot = `data/${tracksSlug}`
function getTrackPath(trackName) {
  return `${tracksPathFromRoot}/${trackName}${tracksExt}`
}

// URLs
// in a way, we use the disk like REST api; you can GET or DELETE (or perhaps, later, PUT/UPSERT) to the same file url... it's just that a static server handles the GET and more normal methods handle everything else.
const originalsUrl = `${STORAGE_API_URL}/${originalsSlug}`
function getOriginalUrl(trackName) {
  return `${originalsUrl}/${trackName}`
}
const tracksUrl = `${STORAGE_API_URL}/${tracksSlug}`
function getTrackUrl(trackName) {
  return `${tracksUrl}/${trackName}${tracksExt}`
}

// If you make this programmatic, be sure to leave a hardcoded exports object in it for autocompletion purposes.
module.exports = {
  tracksExt,

  originalsPathFromRoot,
  tracksPathFromRoot,

  getOriginalPath,
  getTrackPath,

  originalsUrl,
  getOriginalUrl,

  tracksUrl,
  getTrackUrl, // not doign this for now; don't need URLs in backend yet, and would like to not have to switch backend to use import/export instead of require()...
}

// okay, cool. so... looks like I can just use 'import' on this! who knew. makes sense I guess.

if (require.main === module) {
  for (const key in module.exports) {
    if (module.exports.hasOwnProperty(key)) {
      const val = module.exports[key]
      if (typeof val === 'string' || val instanceof String) {
        process.stdout.write(`${key}='${val}'\n`)
      }
    }
  }
}
