import React from 'react'

const RoutineContext = React.createContext()

const RoutineConsumer = RoutineContext.Consumer

export const RoutineProvider = RoutineContext.Provider

export const WithRoutine = Component => {
  return function ComponentWithRoutine(props) {
    return (
      <RoutineConsumer>
        {routine => <Component {...props} routine={routine} />}
      </RoutineConsumer>
    )
  }
}
