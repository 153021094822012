import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Player from './Player'
import Block from './Block'
import { BuilderContext } from '../../contexts/BuilderContext'

import DestinationRoutines from './DestinationRoutines'

import { BACKEND_API_URL } from '../../lib/serverUrls'
import { Debug } from '../../contexts/UserContext'

import headerGetters from '../../lib/headerGetters'
import { WithRoutines } from '../../contexts/RoutinesContext'

const headers = headerGetters.withToken()

function allAMatchB(a, b) {
  for (var key in a) {
    if (JSON.stringify(a[key]) != JSON.stringify(b[key])) {
      const message = `${key}:  ${a[key]} (from a) != ${b[key]} (from b)`
      console.warn(message)
      return false
    }
  }
  return true
}

const blockDefaults = {
  children: [],
  numChildrenReps: 1,
  audioUrl: '',
  audioFile: null,
  audioVolume: 1,
  description: '',
  numReps: 1,
  completesManually: false,
  backgroundVideoUrl: '',
  contentVideoUrl: '',
  pauseDur: 0,
  routine: '',

  lastMoved: 0,
  derpyCreatedFirstChild: false,
}

const apiCalls = {
  getRoutineBlocks: async function(routineId) {
    try {
      var blocks = await fetch(`${BACKEND_API_URL}/routines/${routineId}/blocks`, {
        method: 'GET',
        headers,
      }).then(res => res.json())
    } catch (error) {
      console.error({ error })
    }
    return blocks
  },
  getBlock: async function(blockId) {
    // PASTED, FIX
    fetch(`${BACKEND_API_URL}/blocks/${blockId}`, {
      method: 'GET',
      headers,
    })
      .then(res => res.json())
      .then(blockData => {
        if (blockData) {
          for (const key in blockData) {
            if (blockData.hasOwnProperty(key)) {
              const val = blockData[key]
              if (null === val) {
                delete blockData[key] // react really doesn't like null vals. in theory I could avoid these at the database too... but I can also avoid them here.
              }
            }
          }
          delete blockData._id
          this.setState(blockData) /// FIXME
        }
      })
      .catch(err => console.warn(err))
  },
  newBlock: async function({ routine }) {
    return fetch(`${BACKEND_API_URL}/blocks/`, {
      method: 'POST',
      body: JSON.stringify({ routine }),
      headers,
    }).then(res => res.json())
  },
  updateBlock: async function(blockId, update) {
    return fetch(`${BACKEND_API_URL}/blocks/${blockId}`, {
      headers,
      method: 'PUT',
      body: JSON.stringify(update),
    }).then(res => res.json())
  },
  moveBlockToRoutine: async function(blockId, routineId) {
    return fetch(`${BACKEND_API_URL}/blocks/${blockId}/moveToRoutine/${routineId}`, {
      headers,
      method: 'POST',
    }).then(res => res.json())
  },
}

class Builder extends Component {
  static propTypes = {
    routineId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props) // might be fine to do this another way but this matched the docs here: https://reactjs.org/docs/context.html
    this.state = { blocks: {}, movingBlockId: false } // movingBlockId:false,
  }

  async componentDidMount() {
    this.refreshRoutinesBlocks()
  }

  refreshRoutinesBlocks = async () => {
    const { routineId } = this.props
    this.loadRoutineBlocks(routineId)
  }
  ///////// METHODS:

  /// BASIC CRUD:
  loadRoutineBlocks = async routineId => {
    const blocksRaw = await apiCalls.getRoutineBlocks(routineId)
    const blocks = {}
    blocksRaw.forEach(block => {
      blocks[block._id] = Object.assign({}, blockDefaults, block)
    })
    this.setState({ blocks })
  }

  createNewBlock = async () => {
    const { routineId: routine } = this.props
    const blockData = await apiCalls.newBlock({ routine })
    const blockState = Object.assign({}, blockDefaults, blockData)
    this.relayBlockState(blockData._id, blockState)
    return blockData._id
  }
  addNewBlock = async ({ parentId, index }) => {
    const blockId = await this.createNewBlock()
    this.addBlockToParent({ parentId, index, blockId })
  }
  addBlockToParent = async ({ parentId, index, blockId }) => {
    index == index || 0
    const prevChildrenarr = this.state.blocks[parentId].children
    const updtChildrenarr = prevChildrenarr.slice()
    updtChildrenarr.splice(index, 0, blockId)
    await this.relayBlockUpdate(blockId, { lastMoved: Date.now() })
    return await this.relayBlockUpdate(parentId, { children: updtChildrenarr })
  }
  relayBlockState = (blockId, stateUpdate, cb) => {
    this.setState(oldState => {
      const oldBlock = oldState.blocks[blockId]
      const newBlock = Object.assign({}, oldBlock, stateUpdate)
      const newState = Object.assign({}, oldState)
      newState.blocks[blockId] = newBlock
      return newState
    }, cb)
  }
  relayBlockUpdate = (blockId, update) => {
    const cb = async () => {
      const updatedBlock = await apiCalls.updateBlock(blockId, update)
      allAMatchB(update, updatedBlock)
    }
    return this.relayBlockState(blockId, update, cb)
  }
  //// BLOCK MOVE:
  initializeBlockMove = ({ movingBlockId }) => {
    this.setState({ movingBlockId })
  }
  clearBlockMove = () => {
    this.setState({
      movingBlockId: false,
    })
  }
  falseoutBlockFromParentsState = async blockId => {
    const blocks = this.state.blocks
    for (const parentId in blocks) {
      const parent = blocks[parentId]
      const parentHasBlock = parent.children.filter(childId => childId === blockId).length
      if (parentHasBlock) {
        const newChildrenarr = parent.children.map(childId =>
          childId === blockId ? false : childId
        )
        await this.relayBlockState(parentId, { children: newChildrenarr })
      }
    }
    return true
  }
  clearFalseChildrenFromParents = async opts => {
    const blocks = this.state.blocks
    for (const parentId in blocks) {
      const parent = blocks[parentId]
      if (!parent.children.every(childId => childId)) {
        const newChildrenarr = parent.children.filter(childId => childId)
        if (opts && opts.save === false) {
          await this.relayBlockState(parentId, { children: newChildrenarr })
        } else {
          await this.relayBlockUpdate(parentId, { children: newChildrenarr })
        }
      }
    }
    return true
  }
  completeBlockMoveToBlock = async ({ parentId, index }) => {
    const blockId = this.state.movingBlockId
    await this.falseoutBlockFromParentsState(blockId)
    await this.addBlockToParent({ parentId, index, blockId })
    await this.clearFalseChildrenFromParents()
    this.clearBlockMove()
  }
  completeBlockMoveToRoutine = async (blockId, routineId) => {
    const { blocks } = this.state
    const { routines } = this.props
    const blockDesc = blocks[blockId].description
    const routineDesc = routines.find(r => r._id === routineId).description
    const msg = `Are you sure you want to move the "${blockDesc}" block to the "${routineDesc}" routine?`

    if (confirm(msg)) {
      const response = await apiCalls.moveBlockToRoutine(blockId, routineId)
      if (response.success) {
        await this.falseoutBlockFromParentsState(blockId)
        await this.clearFalseChildrenFromParents({ save: false })
        this.clearBlockMove()
      }
    }
  }

  render() {
    const { rootBlockId, debug, routines, routineId } = this.props
    const { movingBlockId } = this.state

    return (
      <div className="builder">
        <Debug value={{ props: this.props, state: this.state }} name="builder" />
        <BuilderContext.Provider
          value={{
            state: this.state,
            props: this.props,
            methods: {
              addNewBlock: this.addNewBlock,
              relayBlockUpdate: this.relayBlockUpdate,
              relayBlockState: this.relayBlockState,

              initializeBlockMove: this.initializeBlockMove,
              completeBlockMoveToBlock: this.completeBlockMoveToBlock,
              clearBlockMove: this.clearBlockMove,
              refreshRoutinesBlocks: this.refreshRoutinesBlocks,
            },
          }}
        >
          <Player>
            <Block
              _id={rootBlockId}
              {...{ debug }}
              index={0}
              depth={0}
              parentAddPlayFunc={() => {}}
              parentRemoveBlock={() => {}}
              moveInParent={() => {}}
              ancestors={[]}
            />
          </Player>
          {movingBlockId ? (
            <DestinationRoutines
              moveBlockToRoutine={this.completeBlockMoveToRoutine}
              {...{
                movingBlockId,
                routines,
                routineId,
              }}
            />
          ) : null}
        </BuilderContext.Provider>
      </div>
    )
  }
}

export default WithRoutines(Builder)
