import React, { Component } from 'react'

import { Link } from 'react-router-dom'

import { WithUser, Debug } from '../contexts/UserContext'

import RegistrationForm from './common/RegistrationForm'

const { APP_DISPLAY_NAME } = process.env
const t = t => t

class LoginPage extends Component {
  state = {
    username: '',
    password: '',
  }

  componentDidMount() {
    document.title = `${t('Login')} | ${APP_DISPLAY_NAME}`
  }

  handleUsernameChange = e => {
    this.setState({ username: e.target.value })
  }
  handlePasswordChange = e => {
    this.setState({ password: e.target.value })
  }

  logIn = () => {
    const { username, password } = this.state
    this.props.user.logIn({ username, password })
  }
  handleInputKeypress = e => {
    if (e.key === 'Enter') {
      this.logIn()
    }
  }

  render() {
    const { user } = this.props
    const { isAuthenticated } = user

    return (
      <div className="loginPage">
        {isAuthenticated && isAuthenticated()
          ? this.renderLoggedInMessage()
          : this.renderContent()}
      </div>
    )
  }

  renderContent() {
    const { username, password } = this.state
    const { user } = this.props
    const { user: userData, message, lastLoginFailed } = user

    return (
      <div>
        <hr />
        <h1>Log in</h1>
        <Debug value={{ user: userData, message }} />
        <input
          placeholder="username"
          type="text"
          autoCorrect="off"
          autoCapitalize="none"
          value={username}
          onChange={this.handleUsernameChange}
          onKeyPress={this.handleInputKeypress}
        />{' '}
        <input
          placeholder="password"
          value={password}
          type="password"
          onChange={this.handlePasswordChange}
          onKeyPress={this.handleInputKeypress}
        />{' '}
        <button onClick={this.logIn}>Log in</button>
        {lastLoginFailed ? (
          <div className="warning">Incorrect username or password</div>
        ) : null}
        <br />
        <br />
        <hr />
        <RegistrationForm />
        <br />
        <hr />
      </div>
    )
  }

  renderLoggedInMessage() {
    const { user } = this.props
    const { user: userData } = user
    const { username, email } = userData

    return (
      <div>
        <h3>
          Welcome, {username}! &nbsp;<i>({email})</i>
        </h3>
        <h2>
          <Link to="/routines">Go to Routines &raquo;</Link>
        </h2>
      </div>
    )
  }
}

export default WithUser(LoginPage)
