import React from 'react'
import { WithBuilder } from '../../contexts/BuilderContext'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faArrowRight } from '@fortawesome/free-solid-svg-icons'
fLib.add(faPlus, faArrowRight)

export function MoveHereButton({ onClick }) {
  return (
    <span className="forMoving">
      <button title="Move your selected block here" {...{ onClick }}>
        <Icon icon="arrow-right" />
      </button>
    </span>
  )
}
function CreateHereButton({ onClick }) {
  return (
    <span className="forAdding">
      <button {...{ onClick }}>
        <Icon icon="plus" />
      </button>
    </span>
  )
}
function PhantomButton() {
  return (
    <span className="forAdding">
      <button style={{ opacity: 0 }}>
        <Icon icon="plus" />
      </button>
    </span>
  )
}

function Destination({ index, parentId, builder, ancestors }) {
  const moveHere = () => builder.methods.completeBlockMoveToBlock({ parentId, index })
  const createHere = () => builder.methods.addNewBlock({ parentId, index })

  const { movingBlockId } = builder.state
  return (
    <div className="destination">
      {movingBlockId ? (
        ancestors.includes(movingBlockId) ? (
          <PhantomButton />
        ) : (
          <MoveHereButton onClick={moveHere} />
        )
      ) : (
        <CreateHereButton onClick={createHere} />
      )}
    </div>
  )
}

export default WithBuilder(Destination)
