import React from 'react'

export default function ChildrenPlayer({ block, goToNextPhase, goToFirstChild }) {
  if (block.children && block.children.length) {
    goToFirstChild()
  } else {
    goToNextPhase()
  }
  return <div className="children"></div>
}
