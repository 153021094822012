import React from 'react'

import Block from './Block'
import Destination from './Destination'

import PropTypes from 'prop-types'

// import { Debug } from '../../contexts/UserContext'

import { WithBuilder } from '../../contexts/BuilderContext'

// const t = t => t // TODO: you know, internationalization...

// TODO: clear out needless "numreps" for containers

class BlocksContainer extends React.Component {
  static propTypes = {
    parentSetChildren: PropTypes.func.isRequired, // this ridiculous naming bespeaks the name for a more modularized and diversivied data model.
    // ^^^^ to reframe what the comments above are saying: with parentSetChildren and parentSetNumChildrenReps you can see that BlocksContainer is having to weirdly manipulate the parent block. In the v0.2 refactor, BlocksContainer will be a block type, and will handle its own collection there. We're giving ourself this strange situation in v0.1 by using only a single main data model - Block - to cover functionalities of various types (playing audio, containing additional blocks, etc.).
    parentId: PropTypes.string.isRequired,

    blocks: PropTypes.array,
    numReps: PropTypes.number,
    routine: PropTypes.string,
    ancestors: PropTypes.array,
  }

  static defaultProps = {
    blocks: [],
    numReps: 1,
  }

  relayBlocks = async blocks => {
    return await this.props.parentSetChildren(blocks.slice())
  }

  moveBlock = ({ blockId, direction }) => {
    let { blocks } = this.props
    blocks = blocks.slice()
    const initialIndex = blocks.indexOf(blockId)
    blocks.splice(initialIndex, 1)
    let newIndex
    if ('up' == direction) {
      newIndex = Math.max(0, initialIndex - 1)
    } else if ('down' == direction) {
      newIndex = Math.min(initialIndex + 1, blocks.length)
    }
    blocks.splice(newIndex, 0, blockId)
    this.relayBlocks(blocks)
  }
  removeBlock = blockId => {
    let { blocks } = this.props
    blocks = blocks.slice()
    const blockIndex = blocks.indexOf(blockId)
    blocks.splice(blockIndex, 1)
    this.relayBlocks(blocks)
  }
  handleNumRepsChange = e => {
    const numReps = e.target.value
    this.relayNumReps(numReps)
  }

  render() {
    const { depth, debug, parentId, blocks, ancestors } = this.props
    return (
      <div className="blocksContainer">
        <div className="blocks">
          {blocks.map((blockId, index) => {
            return (
              <div key={`${blockId}`}>
                <Destination {...{ index, parentId, ancestors }} />
                {blockId ? (
                  <Block
                    _id={blockId}
                    {...{ index, depth, debug, ancestors }}
                    parentRemoveBlock={this.removeBlock}
                    moveInParent={this.moveBlock}
                  />
                ) : null}
              </div>
            )
          })}
          <Destination index={blocks.length} {...{ parentId, ancestors }} />
          <div style={{ clear: 'both' }} />
        </div>
      </div>
    )
  }
}

export default WithBuilder(BlocksContainer)
