import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import AuthRequired from '../components/common/AuthRequired'
import ApprovalRequired from '../components/common/ApprovalRequired'
import { RoutinesProvider } from '../contexts/RoutinesContext'

import Routine, { makeRoutineFromData } from './Routine'

import { BACKEND_API_URL } from '../lib/serverUrls'

import { Debug } from '../contexts/UserContext'

import headerGetters from '../lib/headerGetters'

import { Helmet } from 'react-helmet'

const { APP_DISPLAY_NAME } = process.env
const t = t => t

const headers = headerGetters.withToken()

export const RoutinesContext = React.createContext([]) // let's see how we feel about doing this...

class Routines extends Component {
  static defaultProps = {
    children: [],
  }

  state = {
    routines: [],
    showArchived: false,
  }

  addRoutine = () => {
    fetch(`${BACKEND_API_URL}/routines`, {
      method: 'POST',
      headers,
    })
      .then(res => res.json())
      .then(routineData => {
        const routine = makeRoutineFromData(routineData)
        this.setState(prevState => {
          return {
            routines: prevState.routines.concat(routine),
          }
        })
      })
  }

  updateRoutineById = (routineId, routineUpdate) => {
    //  I'm noticing that typing goes in a bit slow. Even though it's not waiting on the fetch before setState()...
    this.setState(prevState => {
      // it *SHOULD* just work to pass this func into setAndSaveState(), because it's then passed straight into setState() just like it was when we were using setState() directly)
      // I'm obviously doing a fair amount of work not to overwrite any properties of existing state objects. I'm not sure quite exactly how important that it, but it makes some sense -- you only want SetState to do any modifying...
      const routines = prevState.routines.slice()
      const oldRoutine = routines.filter(routine => routine._id === routineId)[0]
      const updatedRoutine = Object.assign({}, oldRoutine, routineUpdate)
      routines[routines.indexOf(oldRoutine)] = updatedRoutine
      return { routines }
    })
    fetch(`${BACKEND_API_URL}/routines/${routineId}`, {
      // todo: shouldn't this be first? and then clear it from state after? i guess that's slower... and a deletion might as well happen right away... failed delete isn't a great risk
      method: 'PUT',
      headers,
      body: JSON.stringify(routineUpdate),
    })
      .then(res => res.json())
      .then(savedRoutine => {
        savedRoutine // just noting here what comes back...
      })
  }

  deleteRoutineById = routineId => {
    fetch(`${BACKEND_API_URL}/routines/${routineId}`, {
      method: 'DELETE',
      headers,
    })
      .then(res => res.json())
      .then(deletedRoutine => {
        if (deletedRoutine._id === routineId) {
          this.setState(prevState => {
            const routines = prevState.routines.slice()
            const oldRoutine = routines.filter(routine => routine._id === routineId)[0]
            routines.splice(routines.indexOf(oldRoutine), 1)
            return { routines }
          })
        }
      })
  }

  handleShowArchivedChange = e => {
    this.setState({ showArchived: e.target.checked })
  }

  componentDidMount() {
    fetch(`${BACKEND_API_URL}/routines`, {
      method: 'GET',
      headers,
    })
      .then(res => res.json())
      .then(routinesData => {
        this.setState({
          routines: routinesData.map(routineData => {
            return makeRoutineFromData(routineData)
          }),
        })
      })
  }

  render() {
    const { match } = this.props
    const { routines, showArchived } = this.state
    const currentRoutine = routines.find(r => window.location.href.match(r._id))

    return (
      <div id="routines">
        <Debug value={this.state} name={'routines'} />
        <AuthRequired>
          <ApprovalRequired>
            <Debug value={{ match }} name={'match'} />
            {match.isExact && (
              <Helmet>
                <title>
                  {t('Routines')} | {APP_DISPLAY_NAME}
                </title>
              </Helmet>
            )}
            <div id="routinesControls">
              <RoutinesNav
                routines={routines}
                basePath={match.path} // TODO: keep going with this to match routine
                showArchived={showArchived}
                currentRoutine={currentRoutine}
              />
              <label>
                <input
                  type="checkbox"
                  checked={showArchived}
                  onChange={this.handleShowArchivedChange}
                />
                Show Archived
              </label>
              <br />
              <button className="addRoutine" onClick={this.addRoutine}>
                Add Routine
              </button>
            </div>
            {/* https://tylermcginnis.com/react-router-nested-routes/ */}
            {/* https://csb-v8k006wnol-xdwmyvgvdg.now.sh/ */}
            {/* https://tylermcginnis.com/react-router-pass-props-to-components/ */}
            <RoutinesProvider value={routines}>
              {routines
                .filter(routine => window.location.href.match(routine._id)) // something tells me I should be able to use the router to get the routineId but I'm not seeing where... and this is working fine.
                .map(routine => (
                  <div key={routine._id}>
                    <br />
                    <hr />
                    <br />
                    <h2>Current Routine</h2>
                    <Route
                      path={`${match.path}/:routineId`}
                      render={() => (
                        <Routine
                          {...{ routine }}
                          updateRoutineById={this.updateRoutineById}
                          deleteRoutineById={this.deleteRoutineById}
                        />
                      )}
                    />
                  </div>
                ))}
            </RoutinesProvider>
          </ApprovalRequired>
        </AuthRequired>
      </div>
    )
  }
}

const RoutinesNav = ({ routines, basePath, showArchived, currentRoutine }) => {
  return (
    <ul className="routinesNav">
      {routines
        .sort((rA, rB) => {
          if (rA.description < rB.description) return -1
          return 1
        })
        .filter(routine => {
          const { archivedOn } = routine
          return showArchived || !archivedOn
        })
        .map(routine => {
          const { _id, description, archivedOn } = routine
          const isCurrent = routine === currentRoutine
          return (
            <li
              key={_id}
              className={`listedRoutine${isCurrent ? ' current' : ''}${
                archivedOn ? ' archived' : ''
              }`}
            >
              <Link to={`${basePath}/${_id}`}>
                <b>{description || 'unnamed routine'}</b>
              </Link>
            </li>
          )
        })}
    </ul>
  )
}

export default Routines
