import React, { useEffect } from 'react'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { library as fLib, icon } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
fLib.add(faCheck)

export default function ManualCompletionPlayer({ block, goToNextPhase }) {
  const { completesManually, description } = block

  useEffect(() => {
    if (!completesManually) {
      goToNextPhase()
    }
  }, [completesManually])

  if (!completesManually) {
    return null
  }
  return (
    <div className="ManualCompletionPlayer">
      <button style={{ fontSize: '200%' }} onClick={goToNextPhase}>
        <Icon icon="check" /> Done with &quot;<strong>{description}</strong>&quot;
      </button>
    </div>
  )
}
